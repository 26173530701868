const $merge = require('lodash.merge')

const config = require('./config.base')
const loadShell = require('./utils/voicerconfig/load-shell')

const configEnvironments = {
  development: require('./config.development'),
  staging: require('./config.staging'),
  production: require('./config.production'),
  next: require('./config.next'),
}

let configLocal = require('./utils/voicerconfig/load-local')
let envConfig = null

if (process.env.ADD_CONFIG_OPTIONS) {
  try {
    console.log(`🤓 use environment options`)
    envConfig = JSON.parse(process.env.ADD_CONFIG_OPTIONS)
  } catch (error) {
    console.error(`Invalid environment options`)
    console.error(`You passed`, process.env.ADD_CONFIG_OPTIONS)
    console.error(error)
    process.exit(1)
  }
}

const transformConfig = (config) => {
  if (config.useShell) {
    loadShell(config)
  }
  return config
}

/**
 * default configuration file
 * common no matter the BROCOLI_API_MODE
 * you can override properties in
 * config.[BROCOLI_API_MODE].js configurations files
 */
const endConfig = $merge(
  {},
  config,
  {
    /**
     * common configuration here
     */
    siteId: '5d94ae39ef64cf1a4c5b292c',
    showCardDescription: true,
    disableRouterActivelinkComputationBasedOnMode: true,
    // disabledComponents: ['wToolbarSearch'],
    useShell: [
      'bb-multiradios',
      {
        radioHeading: {
          mail: 'mailto:radio@carglass.fr?subject=Contact%20CARGLASS%20Radio',
          phone: 'tel:+33186860087',
          phoneText: `Au 01.86.86.00.87`,
        },
      },
    ],
    analytics: {
      ga: 'UA-111736601-21',
      mixpanel: 'e921d87702d541b5d50593c668206c18',
    },
    metadatas: {
      name: 'CARGLASS® RADIO',
      colorPrimary: '#E31F18',
    },
    filterVisibleFilters({ page }) {
      return (filter) => {
        if (page === 'replay' || page === 'filters') {
          return filter.type === 'datepicker' ||  filter.slug === 'type_de_replay'
        }
        return false
      }
    },
    pages: (pager) => {
      return pager
        .remove('radios')
        .update('filters', {
          options: {
            payload: {
              is: 'podcast',
            },
            filters: true,
          },
        })
        .add({
          slug: 'replay',
          title: 'Replay',
          icon: 'radio_button_checked',
          visibleInMenu: true,

          options: {
            payload: {
              is: 'podcast',
            },
            highlighted: false,
            filter: true,
          },
          router: {
            path: '/replay',
          },
        })
    },
    theme: {
      dark: false,
      components: {
        'toolbar-button': {
          style: (context) => (isActive, currentRouteIndex) => {
            if (currentRouteIndex === 0) {
              return {
                backgroundColor: '#FDDC0A',
              }
            }
            return {}
          },
        },
      },
      fonts: {
        families: {
          Roboto: [300, 400, 500, 700],
          Merriweather: [300, 400, 500],
        },
      },
    },
  },
  configEnvironments[process.env.BROCOLI_API_MODE],
  configLocal,
  envConfig
)

module.exports = transformConfig(endConfig)

const path = require('path')

const colorPrimary = '#E31F18'

module.exports = {
  name: 'bb-multiradios',
  version: '1',
  routes: ['index'],
  config: {
    featuredSectionColor: '#FDDC0A',
    streamsSectionColor: colorPrimary,
    featuredCardsColor: ['#FFE95E'],
    radioHeading: {
      mail: 'mailto:digital@mediameeting.fr',
      phone: 'tel:+33669100972',
      mailText: 'Par mail',
      phoneText: `Au 06.69.10.09.72`,
    },
    store() {
      return {
        contents: {
          middlewares: {
            getContents: {
              success: [require('./middlewares/get-contents-success')],
            },
          },
        },
      }
    },
    blocks() {
      return {
        'radio-heading': require('./blocks/radio-heading.vue'),
      }
    },
  },
  configureBubblecast(config) {
    config.disablePageListen = function () {
      return this.store.state.player.content.type === 'live'
    }
    config.disableAutosetPlayerContent = true
    config.enableExperimentalBlocks = true
    config.routes.store['contents/getContents'] = [] // disable autofetch content on index
    config.routes.store['filters/getCategories'].push(
      'filters',
      'replay'
    )
    config.redirectSearchTo = '/filters'

    config.pages = (pager) => {
      return pager
        .update('default', {
          title: 'Radios',
          visibleInMenu: true,
          icon: 'fiber_manual_record',
          iconVisibleDesktop: true,
          options: {
            payload: {
              is: 'live',
            },
          },
        })
        .update('search', {
          router: {
            path: '/filters',
            mode: 'search',
          },
        })
        .remove('podcasts')
        .remove('radios')
    }
  },
  styleResources: {
    scss: [path.resolve(__dirname, './assets/main.scss')],
  },
  additionalRoutes: [
    {
      name: 'replay',
      path: '/replay',
      component: path.resolve(__dirname, './replay.vue'),
      chunkName: 'pages/replay',
    },
    {
      name: 'filters',
      path: '/filters',
      component: path.resolve(__dirname, './filters.vue'),
      chunkName: 'pages/filters',
    },
  ],
}

import Vue from 'vue'
import Router from 'vue-router'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _0660e1b2 = () => interopDefault(import('../pages/login/index.vue' /* webpackChunkName: "pages/login/index" */))
const _6b36c958 = () => interopDefault(import('../pages/password_reset/index.vue' /* webpackChunkName: "pages/password_reset/index" */))
const _5b25acd0 = () => interopDefault(import('../pages/register/index.vue' /* webpackChunkName: "pages/register/index" */))
const _a9cd4b60 = () => interopDefault(import('../pages/settings/index.vue' /* webpackChunkName: "pages/settings/index" */))
const _6c1cf82c = () => interopDefault(import('../pages/login/redirect.vue' /* webpackChunkName: "pages/login/redirect" */))
const _78e16d9f = () => interopDefault(import('../pages/password_reset/confirmation.vue' /* webpackChunkName: "pages/password_reset/confirmation" */))
const _7fd2d49a = () => interopDefault(import('../pages/password_reset/update.vue' /* webpackChunkName: "pages/password_reset/update" */))
const _e56b2c36 = () => interopDefault(import('../pages/register/activate.vue' /* webpackChunkName: "pages/register/activate" */))
const _0f3be63f = () => interopDefault(import('../pages/data/_id.vue' /* webpackChunkName: "pages/data/_id" */))
const _0fdc97ea = () => interopDefault(import('../pages/listen/_content/index.vue' /* webpackChunkName: "pages/listen/_content/index" */))
const _33d5754b = () => interopDefault(import('../pages/listen/_content/comments.vue' /* webpackChunkName: "pages/listen/_content/comments" */))
const _f2ee6076 = () => interopDefault(import('../excentrics/shells/bb-multiradios/index.vue' /* webpackChunkName: "pages/index" */))
const _4f4ea5f8 = () => interopDefault(import('../excentrics/shells/bb-multiradios/replay.vue' /* webpackChunkName: "pages/replay" */))
const _7b16b8a4 = () => interopDefault(import('../excentrics/shells/bb-multiradios/filters.vue' /* webpackChunkName: "pages/filters" */))

// TODO: remove in Nuxt 3
const emptyFn = () => {}
const originalPush = Router.prototype.push
Router.prototype.push = function push (location, onComplete = emptyFn, onAbort) {
  return originalPush.call(this, location, onComplete, onAbort)
}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: decodeURI('/'),
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/login",
    component: _0660e1b2,
    name: "login"
  }, {
    path: "/password_reset",
    component: _6b36c958,
    name: "password_reset"
  }, {
    path: "/register",
    component: _5b25acd0,
    name: "register"
  }, {
    path: "/settings",
    component: _a9cd4b60,
    name: "settings"
  }, {
    path: "/login/redirect",
    component: _6c1cf82c,
    name: "login-redirect"
  }, {
    path: "/password_reset/confirmation",
    component: _78e16d9f,
    name: "password_reset-confirmation"
  }, {
    path: "/password_reset/update",
    component: _7fd2d49a,
    name: "password_reset-update"
  }, {
    path: "/register/activate",
    component: _e56b2c36,
    name: "register-activate"
  }, {
    path: "/data/:id?",
    component: _0f3be63f,
    name: "data-id"
  }, {
    path: "/listen/:content",
    component: _0fdc97ea,
    name: "listen-content"
  }, {
    path: "/listen/:content?/comments",
    component: _33d5754b,
    name: "listen-content-comments"
  }, {
    path: "/",
    component: _f2ee6076,
    name: "index"
  }, {
    path: "/replay",
    component: _4f4ea5f8,
    name: "replay"
  }, {
    path: "/filters",
    component: _7b16b8a4,
    name: "filters"
  }],

  fallback: false
}

export function createRouter () {
  return new Router(routerOptions)
}
